import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Post from "../components/post";


const BlogPage = ({data}) => {

    const posts = data.allPosts.edges;

    return (
        <Layout>
            <SEO title="Blog" keywords={["blog", "programming"]}/>
            <div className="blog">
                <div className="head">/blog</div>

                <div className="posts">
                {
                    posts.map(post => {
                        const { title, date, tags, slug } = post.node.frontmatter;

                        return (
                            <Post key={slug}
                                title={title}
                                date={date}
                                tags={tags}
                                slug={slug} />
                        )
                    })
                }
                </div>
            </div>
        </Layout>
    )
}
export default BlogPage;

export const query = graphql`
query {
    allPosts: allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
            node {
                frontmatter {
                    title
                    date
                    tags
                    slug
                }
            }
        }
    }
}
`;